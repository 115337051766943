import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPlatformAccountsUpdate } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, createInput, sselectCreateInput, sselectInput, textInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { alreadyExistAccounts } from './lpRightbarUtils/utils'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'
import { fetchCountries } from '../../redux/actions/execution/execution-actions'
import { IMultiSelectItem } from '../../entity/multiselect'
import { useLocation } from 'react-router-dom'

const schema = {
  Login: yup.string().required(),
  Currency: yup.string().required(),
  Group: yup.string().required(),
  CustomSymbolSettingProfile: yup
    .object()
    .shape({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .required(),
  StopOut: yup.number().min(0).required(),
  AdditionalParams: yup.string().max(256),
}

const AccountsRightbar: React.FC<IRightbar> = ({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { gateway } = useSelector((reduxState: any) => reduxState.gateways)
  const { data } = useSelector((state: any) => state.fixConfiguration.fixAccounts)
  const { profile } = useSelector((state: any) => state.fixConfiguration)
  const { Countries } = useSelector((state: any) => state.executionConfiguration)

  const findPlatform = gateway?.Platforms?.find((item: any) => item?.Name === location?.search?.slice(10))
  const findCustomSymbolSettingProfile = profile?.find((elem: IMultiSelectItem) => elem.value === item.CustomSymbolSettingProfile)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      StatementsEmails: buildMultiselectOptionsFromArray(item.StatementsEmails),
      Country: { label: item.Country ?? 'None', value: item.Country },
      CustomSymbolSettingProfile: item.CustomSymbolSettingProfile === '' ? profile[0] : findCustomSymbolSettingProfile,
    },
    schema,
  )
  const dataLogin = data.map((item: any) => item.Login)
  const [, setLoading] = useState(true)

  useEffect(() => {
    if (gateway) {
      dispatch(fetchCountries({ Gateway: gateway.Name, setLoading }))
    }
  }, [gateway]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'add' && Countries.length) {
      setInputState((prev: any) => {
        return {
          ...prev,
          Country: { value: '', label: 'None' },
        }
      })
    }

    if (type === 'modify' && Countries.length && !item.Country) {
      setInputState((prev: any) => {
        return {
          ...prev,
          Country: { value: '', label: 'None' },
        }
      })
    }
  }, [Countries.length]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof inputState.Country?.value === 'undefined') {
      setInputState((prev: any) => {
        return {
          ...prev,
          Country: { value: '', label: 'None' },
        }
      })
    }
  }, [inputState.Country]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inputState.DisableMarginCheck) {
      setInputState((prev: any) => {
        return {
          ...prev,
          DisableStopOut: true,
        }
      })
    }
  }, [inputState.DisableMarginCheck]) // eslint-disable-line react-hooks/exhaustive-deps

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('Login')
        .disabled(type === 'modify')
        .errorMessage(alreadyExistAccounts(type, dataLogin, inputState, errors)),
      textInput('Currency').disabled(type === 'modify'),
      textInput('Group'),
      sselectInput('CustomSymbolSettingProfile', profile),
      sselectCreateInput('Country', buildMultiselectOptionsFromArray([{ value: '', label: 'None' }, ...Countries])),
      textInput('StopOut'),
      textInput('AdditionalParams').skipWhen(item.Platform),
      createInput('StatementsEmails').className('col-6').textWarning(!findPlatform?.StatementsSendingEnabled),
      checkboxInput('MarginOnActivate'),
      checkboxInput('DisableStopOut').disabled(inputState.DisableMarginCheck),
      checkboxInput('DisableMarginCheck'),
    ],
    inputState,
    setInputState,
    'accountsRightbar',
    touched,
    setTouched,
    errors,
  )

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    dispatch(
      fetchPlatformAccountsUpdate({
        action: type,
        params: { ...params },
        body: {
          ...inputState,
          StatementsEmails: inputState.StatementsEmails?.map((item: any) => item.value) ?? [],
          CustomSymbolSettingProfile: inputState.CustomSymbolSettingProfile.value,
          Country: inputState.Country.value,
        },
      }),
    )
    dispatch(hideRightBar())
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`accounts.${type}`} />,
          item: inputs,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button mt-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}

export default AccountsRightbar
