import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useQuery } from '../../../hooks/useQuery'
import { showRightBar } from '../../../redux/actions/rightbar-actions'
import { showModal } from '../../../redux/actions/modal-actions'
import { ModalType } from '../../modals/modals'
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd'
import { Action } from '../../../redux/actions/actions'
import AnimatedContainer from '../../AnimatedContainer'
import { FormattedMessage } from 'react-intl'
import AppButton from '../../AppButton'
import { buildTableHeadersConfigured } from '../../../utils/table-utils'
import { AppBreadcrumb } from '@t4b/core'
import { breadcrumbItems } from '../../../utils/breadcrumb-gen'
import { FeedExecPresetEntity } from '../../../entity/Preset-entitu'
import SaveWarning from '../../SaveWarning'
import {
  changeFeedingPreset,
  deleteFeedPreset,
  deleteFeedPresetOne,
  feedingConfigurationChanged,
  changeFeedingLpImport,
  addFeedingLpImport,
  fetchFeeding,
} from '../../../redux/actions/feeding-actions'
import { downloadFileJson } from '../../../utils/fetch-utils'
import { throwErrorMessage, throwSuccessMessage } from '../../../utils/errors-utils'
import SaveTable from '../../SaveTable'
import { faFileImport } from '@fortawesome/free-solid-svg-icons'
import { addFeedAggregationImport, addFeedPlatform, changeFeedAggregationImport, changeFeedPlatformImport } from '../../../redux/actions/execution/execution-actions'

const TableHeader = [
  { name: 'Id', show: true, sortable: false },
  { name: 'Name', show: true, sortable: false },
  { name: 'Description', show: true, sortable: false },
  { name: 'Schedule', show: true, sortable: false },
  { name: 'Enabled', show: true, sortable: false },
  { name: 'Actions', show: true, sortable: false },
]

const FeedPreset = () => {
  const dispatch = useDispatch()
  const local = useLocation()
  const platformName = useQuery().get('platform')
  const { gateway } = useSelector((reduxState: any) => reduxState.gateways)
  const feedingConfiguration = useSelector((state: any) => state.feedingConfiguration)
  const [inputState, setInputState] = useState<any>({
    Gateway: '',
    Platform: '',
  })
  const [files, setFiles] = useState<any>({})
  const [didMount, setDidMount] = useState(false)
  const [, setLoading] = useState(true)
  const table: any = {}
  const tablePreset = feedingConfiguration.Profile.filter((item: FeedExecPresetEntity) => !table[item?.Name] && (table[item?.Name] = 1))

  useEffect(() => {
    if (!feedingConfiguration.noChanges) {
      window.onunload = function () {
        return '...'
      }
      window.onbeforeunload = function () {
        return '...'
      }
    }
    return () => {
      window.onbeforeunload = function () {}
      window.onunload = function () {}
    }
  }, [feedingConfiguration.noChanges]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!feedingConfiguration.Profile.length && gateway.Name) {
      dispatch(fetchFeeding({ ...inputState, Gateway: gateway.Name, setLoading }))
    }
  }, [gateway, feedingConfiguration.Profile.length]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gateway.Name) {
      const newState = {
        Gateway: gateway.Name,
        Platform: platformName,
      }

      setInputState({
        ...inputState,
        ...newState,
      })
    }
  }, [gateway]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setDidMount(true), [])
  useEffect(() => {
    if (didMount) {
      try {
        const feedPlatformsName = feedingConfiguration.feedPlatforms.map((el: any) => el.Id)
        const feedAggregationsName = feedingConfiguration.feedAgregations.map((el: any) => el.Id)
        const lpsName = feedingConfiguration.lp.map((el: any) => el.Id)
        const ProfileId = feedingConfiguration.Profile.map((el: any) => el.Id)
        if (files) {
          const feedPlatforms = files.FeedPlatforms ?? files.feedPlatforms
          const feedAgregations = files.FeedingPool
          const lps = files.Lps || files.lp
          const Profile = files.Profile

          const f = feedAgregations.map((item: any) => {
            return {
              changed: item.changed,
              highlighted: item.highlighted,
              activeSwitches: item.activeSwitches,
              Id: item.Id,
              Name: item.Name,
              Type: item.Type,
              bunchProfiles: item.bunchProfiles,
              Feeders: item.LiquidityProviders,
            }
          })

          f.forEach((el: any) => {
            if (feedAggregationsName?.includes(el.Id)) {
              // dispatch(changeFeedAggregationImport({ ...el }, true))
              return
            } else {
              el.Id = +el.Id > 0 ? -1 * +el.Id : +el.Id
              // dispatch(addFeedAggregationImport({ ...el, flag: true }))
            }
          })

          dispatch({ type: Action.GotFeedAgregation, data: [...f] })

          const feedPlatformsIdAgg = feedingConfiguration?.feedAgregations?.map((item: any) => item.Id)

          let t = feedPlatforms?.map((item: any) => {
            const newItem = {
              changed: item.changed,
              highlighted: item.highlighted,
              activeSwitches: item.activeSwitches,
              Id: item.Id,
              Name: item.Name,
              Connector: item.Connector,
              Type: item.Type,
              Rules: item.Rules.map((item: any) => {
                return {
                  ...item,
                  Id: item.Id > 0 ? item.Id * -1 : item.Id,
                  MarketDepthVolumeMultiplier: item.MarketDepthVolumeMultiplier > 0 ? item.MarketDepthVolumeMultiplier : 1,
                }
              }),
              Enabled: item.Enabled,
              Profile: item.Profile,
              AggregatedFeeder: item.FeedingPool,
              BackupFeedAggregationId: item.BackupFeedingPool,
              FilterEnabled: item.FilterEnabled,
              LimitationEnabled: item.LimitationEnabled,
              FilterInvalidQuotesEnabled: item.FilterInvalidQuotesEnabled,
              DeliveryToPlatformDisabled: item.DeliveryToPlatformDisabled,
            }

            if (feedPlatformsName.includes(newItem.Id)) {
              return newItem
            } else {
              if (feedPlatformsIdAgg.includes(newItem.AggregatedFeeder)) {
                newItem.Id = newItem.Id > 0 ? -1 * Number(newItem.Id) : -Math.floor(Math.random() * (10000 - 1 + 1)) + 1
              } else {
                newItem.Id = newItem.Id > 0 ? -1 * Number(newItem.Id) : -Math.floor(Math.random() * (10000 - 1 + 1)) + 1
                newItem.AggregatedFeeder = Number(newItem.AggregatedFeeder) > 0 ? -1 * Number(newItem.AggregatedFeeder) : Number(newItem.AggregatedFeeder)
              }
            }
            return newItem
          })

          dispatch({ type: Action.GotFeedPlatforms, data: [...t] })
          lps.forEach((el: any) => {
            if (lpsName.includes(el.Id)) {
              return
            }
          })

          Profile.forEach((el: any) => {
            if (ProfileId.includes(el.Id)) {
              return
            } else {
              el.Id = -Math.floor(Math.random() * (10000 - 1 + 1)) + 1
            }
          })

          dispatch({ type: Action.GotLP, data: [...lps] })
          dispatch({ type: Action.GotPreset, data: [...Profile] })
          dispatch(feedingConfigurationChanged())
          throwSuccessMessage('Successfully')
        } else {
          const feedPlatforms = files.FeedPlatforms ?? files.feedPlatforms
          const feedAgregations = files.FeedingPool
          const lps = files.lp
          const Profile = files.Profile

          feedAgregations
            .map((item: any) => {
              return {
                changed: item.changed,
                highlighted: item.highlighted,
                activeSwitches: item.activeSwitches,
                Id: item.Id,
                Name: item.Name,
                Type: item.Type,
                bunchProfiles: item.bunchProfiles,
                Feeders: item.LiquidityProviders,
              }
            })
            .forEach((el: any) => {
              if (feedAggregationsName?.includes(el.Id)) {
                return
              } else {
                // el.Id = el.Id = -1 * Number(el.Id)
                dispatch(addFeedAggregationImport({ ...el, flag: true }))
              }
            })

          // dispatch({ type: Action.GotFeedAgregation, data: [...feedAgregations] })

          const feedPlatformsIdAgg = feedingConfiguration.feedAgregations?.map((item: any) => item.Id)
          feedPlatforms
            .map((item: any) => {
              return {
                changed: item.changed,
                highlighted: item.highlighted,
                activeSwitches: item.activeSwitches,
                Id: item.Id,
                Name: item.Name,
                Connector: item.Connector,
                Type: item.Type,
                Rules: item.Rules.map((item: any) => {
                  return {
                    ...item,
                    Id: item.Id > 0 ? item.Id * -1 : item.Id,
                  }
                }),
                Enabled: item.Enabled,
                Profile: item.Profile,
                AggregatedFeeder: item.FeedingPool,
                BackupFeedAggregationId: item.BackupFeedingPool,
                FilterEnabled: item.FilterEnabled,
                LimitationEnabled: item.LimitationEnabled,
                FilterInvalidQuotesEnabled: item.FilterInvalidQuotesEnabled,
                DeliveryToPlatformDisabled: item.DeliveryToPlatformDisabled,
              }
            })
            .forEach((el: any) => {
              if (feedPlatformsName.includes(el.Id)) {
                return
              } else {
                if (feedPlatformsIdAgg.includes(el.AggregatedFeeder)) {
                  el.Id = el.Id > 0 ? -1 * Number(el.Id) : -Math.floor(Math.random() * (10000 - 1 + 1)) + 1
                } else {
                  el.Id = el.Id > 0 ? -1 * Number(el.Id) : -Math.floor(Math.random() * (10000 - 1 + 1)) + 1
                  el.AggregatedFeeder = Number(el.AggregatedFeeder) > 0 ? -1 * Number(el.AggregatedFeeder) : Number(el.AggregatedFeeder)
                }
              }
            })

          dispatch({ type: Action.GotFeedPlatforms, data: [...feedPlatforms] })

          lps.forEach((el: any) => {
            if (lpsName.includes(el.Id)) {
              return
            }
          })

          Profile.forEach((el: any) => {
            if (ProfileId.includes(el.Id)) {
              return
            } else {
              el.Id = -Math.floor(Math.random() * (10000 - 1 + 1)) + 1
            }
          })

          dispatch({ type: Action.GotLP, data: [...lps] })
          dispatch({ type: Action.GotPreset, data: [...Profile] })
          dispatch(feedingConfigurationChanged())
          throwSuccessMessage('Successfully')
        }
      } catch (e) {
        throwErrorMessage('IncorrectFile')
      }
    }
  }, [files]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = (item: any) => {
    const activePlatform = feedingConfiguration?.feedPlatforms?.filter((elm: any) => elm?.Profile === item?.Name).map((item: any) => item?.Name)

    if (activePlatform.length) {
      dispatch(
        showModal(ModalType.General, {
          headerText: 'preset.modal.header',
          bodyText: () => (
            <div>
              <div>
                <FormattedMessage id="preset.modal.activeOne" values={{ Name: item.Name, feedPlatformsList: activePlatform.join(', ') }} />
              </div>
              <div className="mt-2">
                <FormattedMessage id="preset.modal.activeTwo" values={{ Name: item.Name, feedPlatformsList: activePlatform.join(', ') }} />
              </div>
            </div>
          ),
          onYes: () => {
            dispatch(deleteFeedPresetOne(item))
            dispatch(feedingConfigurationChanged())
          },
        }),
      )
    } else {
      dispatch(
        showModal(ModalType.General, {
          headerText: 'preset.modal.header',
          bodyText: () => <FormattedMessage id="preset.modal.body" values={{ Name: item.Name }} />,
          onYes: () => {
            dispatch(deleteFeedPresetOne(item))
            dispatch(feedingConfigurationChanged())
          },
        }),
      )
    }
  }

  const handleDeleteAll = () => {
    dispatch(
      showModal(ModalType.Confirm, {
        headerText: 'preset.modal.delete-all',
        bodyText: () => (
          <div>
            <div>
              <FormattedMessage id="bodyTextFeedOne" />
            </div>
            <div>
              <b>WARNING:</b> <FormattedMessage id="bodyTextFeedTwo" />
            </div>
            <div className="mb-2 mt-2">
              <FormattedMessage id="bodyTextConfirm" />
            </div>
          </div>
        ),
        onYes: () => {
          dispatch(deleteFeedPreset())
          dispatch(feedingConfigurationChanged())
        },
      }),
    )
  }

  const handleClone = (item: any) => {
    dispatch(
      showRightBar('FeedExecPreset', {
        type: 'clone',
        params: {
          ...inputState,
        },
        item: new FeedExecPresetEntity({ ...item, Name: '', PresetId: Math.floor(Math.random() * (1000 + 1 - 1)) }),
        prevPresetName: item.Name,
      }),
    )
  }

  const handleClick = (item: any) => {
    dispatch(
      showRightBar('FeedExecPreset', {
        type: 'modify',
        params: {
          ...inputState,
        },
        item: new FeedExecPresetEntity(item),
        prevPresetName: item.Name,
      }),
    )
  }

  const handleAdd = () => {
    dispatch(
      showRightBar('FeedExecPreset', {
        type: 'add',
        params: {
          ...inputState,
        },
        item: new FeedExecPresetEntity({}),
      }),
    )
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }
    dispatch({
      type: Action.ChangePriorityFeedPreset,
      data: { result },
    })
    dispatch(feedingConfigurationChanged())
  }

  const importPresetAll = (e: any | null) => {
    const fileReader = new FileReader()
    fileReader.readAsText(e.target.files[0], 'UTF-8')
    fileReader.onload = (e: any | null) => {
      try {
        const file = JSON.parse(e.target.result)
        if (file.version !== 6) {
          throwErrorMessage('FileVersionError')
          return
        } else {
          setFiles(file)
        }
      } catch (e) {
        throwErrorMessage('FileReadError')
      }
    }
  }

  const [onePreset, setOnePreset] = useState({ lp: [], feedPlatforms: [], Profile: [], feedAgregations: [], FeedingPool: [] })
  const importPresetOne = (e: any | null) => {
    const fileReader = new FileReader()
    fileReader.readAsText(e.target.files[0], 'UTF-8')
    fileReader.onload = (e: any | null) => {
      try {
        const file = JSON.parse(e.target.result)
        if (file.version !== 6) {
          throwErrorMessage('FileVersionError')
          return
        } else {
          setOnePreset(file)
        }
      } catch (e) {
        throwErrorMessage('FileReadError')
      }
    }
  }

  useEffect(() => {
    if (onePreset?.Profile?.length) {
      const lpId = feedingConfiguration.lp?.map((item: any) => item.Id)
      onePreset?.lp.forEach((item: any) => {
        if (lpId?.includes(item.Id)) {
          dispatch(changeFeedingLpImport(item))
        } else {
          dispatch(addFeedingLpImport(item))
        }
      })

      const feedPlatformsId = feedingConfiguration.feedPlatforms?.map((item: any) => item.Id)
      const feedPlatformsIdAgg = feedingConfiguration.feedAgregations?.map((item: any) => item.Id)

      onePreset?.feedPlatforms
        ?.map((item: any) => {
          return {
            changed: item.changed,
            highlighted: item.highlighted,
            activeSwitches: item.activeSwitches,
            Id: item.Id,
            Name: item.Name,
            Connector: item.Connector,
            Type: item.Type,
            Rules: item.Rules.map((item: any) => {
              return {
                ...item,
                Id: item.Id > 0 ? item.Id * -1 : item.Id,
                MarketDepthVolumeMultiplier: item.MarketDepthVolumeMultiplier > 0 ? item.MarketDepthVolumeMultiplier : 1,
              }
            }),
            Enabled: item.Enabled,
            Profile: item.Profile,
            AggregatedFeeder: item.FeedingPool,
            BackupFeedAggregationId: item.BackupFeedingPool,
            FilterEnabled: item.FilterEnabled,
            LimitationEnabled: item.LimitationEnabled,
            FilterInvalidQuotesEnabled: item.FilterInvalidQuotesEnabled,
            DeliveryToPlatformDisabled: item.DeliveryToPlatformDisabled,
          }
        })
        ?.forEach((el: any) => {
          if (feedPlatformsId.includes(el.Id)) {
            dispatch(changeFeedPlatformImport({ ...el }))
          } else {
            if (feedPlatformsIdAgg.includes(el.AggregatedFeeder)) {
              dispatch(addFeedPlatform({ ...el, flag: true }))
            } else {
              dispatch(addFeedPlatform({ ...el, AggregatedFeeder: el.AggregatedFeeder > 0 ? -1 * Number(el.AggregatedFeeder) : Number(el.AggregatedFeeder), flag: true }))
            }
          }
        })

      const AggregatedId = feedingConfiguration.feedAgregations?.map((item: any) => item.Id)
      onePreset?.FeedingPool?.map((item: any) => {
        return {
          changed: item.changed,
          highlighted: item.highlighted,
          activeSwitches: item.activeSwitches,
          Id: item.Id,
          Name: item.Name,
          Type: item.Type,
          bunchProfiles: item.bunchProfiles,
          Feeders: item.LiquidityProviders,
        }
      })?.forEach((item: any) => {
        if (AggregatedId?.includes(item.Id)) {
          dispatch(changeFeedAggregationImport({ ...item }))
        } else {
          dispatch(addFeedAggregationImport({ ...item, flag: true }))
        }
      })

      const PresetId = feedingConfiguration.Profile?.map((item: any) => item.Id)
      onePreset?.Profile.forEach((item: any) => {
        if (PresetId.includes(item.Id)) {
          dispatch(changeFeedingPreset(item))
        } else {
          dispatch({
            type: Action.GotFeedingPreset,
            data: new FeedExecPresetEntity({ ...item, Id: -1 * Math.floor(Math.random() * (1000 + 1 - 1)) }),
          })
        }
      })

      dispatch(feedingConfigurationChanged())
      throwSuccessMessage('Successfully')
    }
  }, [onePreset]) // eslint-disable-line react-hooks/exhaustive-deps

  const saveOptions = [
    {
      translateKey: 'import-page-one',
      callback: () => {},
      onChange: importPresetOne,
      flag: true,
    },
    {
      translateKey: 'import-page-all',
      callback: () => {},
      onChange: importPresetAll,
      flag: true,
    },
  ]

  const ImportJSON = async (item: any) => {
    const exportOnePreset = {
      FeedingPool: feedingConfiguration.feedAgregations
        .filter((el: any) => el.bunchProfiles.includes(item.Name))
        .map((item: any) => {
          return {
            changed: item.changed,
            highlighted: item.highlighted,
            activeSwitches: item.activeSwitches,
            Id: item.Id,
            Name: item.Name,
            Type: item.Type,
            bunchProfiles: item.bunchProfiles,
            LiquidityProviders: item.Feeders,
          }
        }),
      feedPlatforms: feedingConfiguration.feedPlatforms
        .filter((el: any) => el.Profile === item.Name)
        .map((item: any) => {
          return {
            changed: item.changed,
            highlighted: item.highlighted,
            activeSwitches: item.activeSwitches,
            Id: item.Id,
            Name: item.Name,
            Connector: item.Connector,
            Type: item.Type,
            Rules: item.Rules.map((item: any) => {
              return {
                ...item,
                Id: item.Id > 0 ? item.Id * -1 : item.Id,
              }
            }),
            Enabled: item.Enabled,
            Profile: item.Profile,
            FeedingPool: item.AggregatedFeeder,
            BackupFeedingPool: item.BackupFeedAggregationId,
            FilterEnabled: item.FilterEnabled,
            LimitationEnabled: item.LimitationEnabled,
            FilterInvalidQuotesEnabled: item.FilterInvalidQuotesEnabled,
            DeliveryToPlatformDisabled: item.DeliveryToPlatformDisabled,
          }
        }),
      lp: feedingConfiguration.lp,
      Profile: feedingConfiguration.Profile.filter((el: any) => el.Name === item.Name),
      version: 6,
    }

    const jsonObj = JSON.stringify(exportOnePreset, null, 2)
    await downloadFileJson('FeedingConfiguration', jsonObj)
  }

  const renameFeedConf = {
    FeedingPool: feedingConfiguration.feedAgregations.map((item: any) => {
      return {
        changed: item.changed,
        highlighted: item.highlighted,
        activeSwitches: item.activeSwitches,
        Id: item.Id,
        Name: item.Name,
        Type: item.Type,
        bunchProfiles: item.bunchProfiles,
        LiquidityProviders: item.Feeders,
      }
    }),
    feedPlatforms: feedingConfiguration.feedPlatforms.map((item: any) => {
      return {
        changed: item.changed,
        highlighted: item.highlighted,
        activeSwitches: item.activeSwitches,
        Id: item.Id,
        Name: item.Name,
        Connector: item.Connector,
        Type: item.Type,
        Rules: item.Rules,
        Enabled: item.Enabled,
        Profile: item.Profile,
        FeedingPool: item.AggregatedFeeder,
        BackupFeedingPool: item.BackupFeedAggregationId,
        FilterEnabled: item.FilterEnabled,
        LimitationEnabled: item.LimitationEnabled,
        FilterInvalidQuotesEnabled: item.FilterInvalidQuotesEnabled,
        DeliveryToPlatformDisabled: item.DeliveryToPlatformDisabled,
      }
    }),
    lp: feedingConfiguration.lp,
    Profile: feedingConfiguration.Profile,
    version: 6,
  }

  return (
    <>
      <div className="d-flex">
        <h2>
          <FormattedMessage id="feeding-configuration.title.preset" />
        </h2>
        <SaveWarning noChanges={feedingConfiguration.noChanges} link="/feeding-configuration" />
      </div>
      <AppBreadcrumb items={breadcrumbItems(local.pathname)} />
      <AnimatedContainer loading={false}>
        <div>
          <div className="row">
            <div className="col-12 d-flex justify-content-end ">
              <AppButton
                variant="export-all"
                onClick={() => downloadFileJson('FeedingConfiguration', JSON.stringify({ ...renameFeedConf, version: 6 }, null, 2))}
                className="mr-2 mb-2"
              />
              <SaveTable key="save-table-options" text={'Import'} icon={faFileImport} items={saveOptions} />
              <AppButton variant="delete-all" onClick={handleDeleteAll} className="mr-2 mb-2" />
              <AppButton variant="add" onClick={handleAdd} className="mr-2 mb-2" />
            </div>
            <div className="col-12">
              <DragDropContext onDragEnd={onDragEnd}>
                <table className="table app-table table-hover" style={{ tableLayout: 'fixed' }}>
                  <thead>
                    <tr style={{ color: '#8dabc4' }}>{buildTableHeadersConfigured(TableHeader, { field: '', by: '' }, () => {}, 'preset')}</tr>
                  </thead>
                  <Droppable droppableId="table">
                    {(droppableProvided: DroppableProvided) => (
                      <tbody ref={ref => droppableProvided.innerRef(ref)} {...droppableProvided.droppableProps}>
                        {tablePreset.map((item: any, index: number) => {
                          const onClone = () => handleClone(item)
                          const onDelete = () => handleDelete(item)
                          const onModify = () => handleClick(item)
                          return (
                            <Draggable draggableId={(item.PresetId + index).toString()} index={index} key={(item.PresetId + index).toString()}>
                              {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                <tr
                                  onClick={onModify}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={
                                    snapshot.isDragging
                                      ? {
                                          display: 'table',
                                          tableLayout: 'fixed',
                                          ...provided.draggableProps.style,
                                        }
                                      : {
                                          display: 'table-row',
                                          ...provided.draggableProps.style,
                                        }
                                  }
                                >
                                  <td>{Math.abs(item.Id)}</td>
                                  <td style={{ width: '200px', wordWrap: 'break-word' }}>{item.Name}</td>
                                  <td style={{ width: '200px', wordWrap: 'break-word' }}>{item.Description}</td>
                                  <td>{item.ScheduleType}</td>
                                  <td>
                                    <input type="checkbox" checked={item.Enabled} />
                                  </td>
                                  <td onClick={(event: any) => event.stopPropagation()} className="d-flex justify-content-center">
                                    <AppButton variant="export-all-tabled" onClick={() => ImportJSON(item)} className="mb-2 mr-2 " />
                                    <AppButton variant="clone" onClick={onClone} className="mb-2 mr-2 " />
                                    <AppButton variant="delete" onClick={onDelete} className="mb-2 mr-2 " />
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          )
                        })}
                        {tablePreset && tablePreset.length === 0 ? (
                          <tr>
                            <td className="text-center" colSpan={6}>
                              <FormattedMessage id="table.nodata" />
                            </td>
                          </tr>
                        ) : null}
                        {droppableProvided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
            </div>
          </div>
        </div>
        {/*<ScrollTopButton top={300} flag={true} />*/}
      </AnimatedContainer>
    </>
  )
}

export default memo(FeedPreset)
